@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .anchor-middle {
    text-anchor: middle;
  }
  .baseline-middle {
    dominant-baseline: middle;
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Noto+Sans:wght@200;300;400;600;900&display=swap');
